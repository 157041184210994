<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>领券页设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="add(null)">新增领券页</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%">
        <el-table-column label="标题" prop="title" />
        <el-table-column label="领券字段" prop="field" />
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="结束时间" prop="endTime" >
          <template slot-scope="scope">
            <span :style="{'color': scope.row.ifExpire ? '#EF3535' : '#'}">{{scope.row.endTime}}</span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="props">
            <el-button type="primary" @click="add(props.row)">编辑</el-button>
            <el-button type="danger" @click="_delete(props.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    
  </div>
</template>
//
<script>
export default {
  //                   action="https://adf.lvzhangkeji.com/api/OrderAdminModule/adolf-order/OrderInExcel"

  data () {
    return {
      list: [],
      dialogVisible: false,
      name: '',
      nickName: '',
      editId: null,
    }
  },
  created () {
    this.GetList();
  },
  methods: {
    GetList () {
      this.$api.getWelfare().then((res) => {
        if (res.code !== 200) this.$message.error('请求失败')
        this.list = res.data
      })
    },
    _delete(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteWelfare({id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    add (data) {
      let p = '';
      if(data){
        p = '?id=' + data.id;
        localStorage.setItem('welfare-' + data.id, JSON.stringify(data));
      }
      this.$router.push('/addWelfare' + p)
    },

  },
}
</script>
<style lang="scss" scoped>

</style>
